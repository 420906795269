:root {
  --blue: #2dc2e3;
  --yellow: #fddf00;
  --green: #4BC263;
  --dark: #17181D;
  --red: #DB2B35;
  --orange: #F57700;
  --primary: #fddf00;
  --gray-dark: #212228;
  --gray: #222429;
  --highlight: #1e2025;
  --gray-border: #434343;
  --text-muted: #B2B2B2;
  --text-yellow-muted: #867703;
  --bs-heading-color: #FFFFFF;
  --bs-navbar-color: #FFFFFF;
  --bs-nav-link-color: #FFFFFF;
  --bs-card-color: #FFFFFF;
  --bs-dark-rgb: 23, 24, 29;
  --bs-btn-close-color: #FFFFFF !important;

  --locked-color: #5fadbf;
  --unlocked-color: #ff5153;

  --bs-accordion-active-bg: red !important;

  --bs-accordion-bg: #17181d !important;
  --bs-body-color: #fff !important;
  --bs-body-bg: #17181d !important;
  --bs-accordion-active-bg: var(--gray-dark) !important;
  --bs-accordion-active-color: #fff !important;

  --indigo: #6610f2;
  --purple: #c16bb1;
  --pink: #e83e8c;
  --teal: #20c997;
  --cyan: #2dc2e3;
  --white: #fff;
  --secondary: #6c757d;
  --success: #4BC263;
  --info: #2dc2e3;
  --warning: #fddf00;
  --danger: #eb4454;
  --light: #f8f9fa;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

@import "overrides/buttons";
@import "overrides/margins";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* bebas-neue-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bebas/bebas-neue-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/bebas/bebas-neue-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-display: swap;
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/redesign/MaterialIcons-Regular.ttf") format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #373737 inset !important;
  -webkit-text-fill-color: #fff !important;
}

#trailShareInput{
  color: #ffffff
}


.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

small .material-icons {
  font-size: 12px;
}

/* GLOBAL */

html{
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

#app {
  overflow: hidden;
}

body {
  //line-height: 1.7;
  font-family: Montserrat, Helvetica, Arial, sans-serif;

  /* background: url(~@/assets/img/main-bg.jpg) no-repeat top center;  */
  background-color:var(--dark);
  color: #fff;
}

a{
  text-decoration: none;
}

a[type="button"] {
  appearance: none;
}

select option{
  background-color: var(--gray-dark);
  color: #fff;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: var(--gray-dark);
  //box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  box-shadow: none;
}

.accordion-header, .accordion-button{
  background: var(--gray-dark);
}

.accordion-item {
  border: 1px solid #434343;
  border-radius: 7px;
  overflow: hidden;
}

// WINDOWS SCROLLBARS

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar (for horizontal scrollbars) */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: var(--dark); /* Color of the scrollbar track */
}

/* Thumb of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #52555d; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

/* Hover effect on scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #52555d; /* Darker color when hovering over the scrollbar thumb */
}

.display-xxl {
  font-size: 3.5rem !important;
  font-weight: 800;
}

.display-xl {
  font-size: 3rem !important;
  font-weight: 800;
}

.display-1 {
  font-size: 2.5rem !important;
}

.display-2 {
  font-size: 2rem !important;
}

.display-3 {
  font-size: 3.5rem !important;
}

.display-4{
  font-size: 1.5rem !important;
  line-height: 1.4;
}

.display-5{
  font-size: 1.25em !important;
}

.display-6{
  font-size: 1.1em !important;
  color: #494848;
}

.rounded-lg{
  border-radius: 0.5rem !important;
}

.two-lines{
  width:100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.three-lines{
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

button:focus {
  outline: none;
}

.h2,
h1 {
  line-height: 1.1em;
}

b,
strong {
  font-weight: 700;
}

p {
  margin-bottom: 0.5em;
}

u {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.p-4{
  padding: 2em !important;
}

.px-5{
  padding-left: 2.5em !important;
  padding-right: 2.5em !important;
}

.p-5{
  padding: 2.5em !important;
}

.border-secondary{
  border: 1px solid #6e6e6e;
  border-radius: 10em;
}

.form-control {
  background-color: var(--gray-dark);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2)
}

.form-control:focus, .custom-file-input:focus {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder{
  color: #898989;
}

.dropdown-menu{
  top: 80px;
  left: unset;
  right: 0;
  background-color: var(--dark);
  padding: 0;
  width: max-content;
}

.dropdown-item{
  color: #fff;
  border-bottom: 1px solid #494848;
  padding: 1rem 1.5rem;
}

.dropdown-item:hover{
  background-color: #505259;
  color: #fff;
}

.beta-test-form .form-control {
  background-color: var(--gray-dark);
  border: none;
  color: #fff;
}

.custom-file-label{
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #898989;
}

.custom-file-label::after{
  color: #fff;
  background-color: #3f3f3f;
}

.square{
  aspect-ratio: 1 / 1;
  img{
    object-fit: cover;
    vertical-align: bottom;
    height: 100% !important;
    width: 100% !important;
  }
}

.fill-available{
  width: stretch;
}

.toasted{
  background: #70c381 !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #173b1f !important;
  padding: 1em 1.5em !important;
}

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 20px;
  width: 20px;
  // background-color: #fff;
  border: 1px solid #fff;
  right: 0;
  left: auto;
  border-radius: 100%;
  margin: auto;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: var(--dark);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: none;

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg-yellow {
  background-color:var(--yellow);
}

.bg-white {
  background-color: #fff !important;
}

.bg-dark {
  background-color: var(--gray-dark) !important;
  border: 1px solid #434343;
}

.bg-gray{
  background: var(--gray) !important;
}

.bg-highlight{
  background: var(--highlight) !important;
}

.text-yellow {
  color: var(--yellow);
}

.text-yellow-muted {
  color: var(--text-yellow-muted);
}

.text-orange {
  color: #f57700;
}

.text-green {
  color: #70c381;
}

.text-muted {
  color: #a6a6a6 !important;
}

.text-spaced{
  letter-spacing: .1em;
}

.bg-highlight {
  background: #363636;
}

section {
  margin-bottom: 4rem !important;
}

.py-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.border-right{
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-left{
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}

hr {
  border-top: 1px solid rgb(110 110 110);
}

.btn {
  /* color: #fff; */
  padding: 0.75em 1em;
  height: auto !important;
  font-weight: 600;
}

.btn-sm{
  .material-icons{
    font-size: 16px;
  }
}

.btn-xs{
  font-size: .75em;
}

.btn-secondary {
  color: #fff;
  background-color: var(--gray-dark);
}

.btn-secondary:hover{
  background-color: #333333;
  border-color: transparent;
}

.btn-secondary.focus, .btn-secondary:focus{
  background-color: #333333;
  border-color: transparent;
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle{
  background-color: #333333;
  border-color: transparent;
}

.btn-light{
  color: #505259;
  background-color: #fff;
}

.btn-light:hover{
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light.focus, .btn-light:focus{
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle{
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-primary,
.btn-primary:hover {
  color: #36220f;
}

.btn-success,
.btn-success:hover {
  color: #173b1f;
}

.btn-info,
.btn-info:hover {
  color: var(--dark);
}

.btn-warning,
.btn-warning:hover {
  color: #36220f;
}

.btn-warning {
  background: #f57700;
  border-color: #f57700;
}

.btn-warning:hover {
  background: #f86916;
  border-color: #f86916;

  /* color: #fff; */
}

.btn.focus, .btn:focus{
  box-shadow: none !important;
}

.btn-link{
  font-weight: 400;
  color: var(--yellow);
  text-decoration: none;
  background: transparent;
  border: none;
}

.btn-link:hover, .btn:focus{
  text-decoration: none;
  opacity: .8;
}

.btn-google {
  background-color: #fff;
  color: var(--dark);
}

.btn-fb {
  background-color: #3b5998;
}

.btn-apple {
  background-color: #111;
}

.btn-clear{
  background-color: rgba(32, 32, 32, 0.733);
}

#map-tools-container{
  position: absolute;
  right: 30px;
  bottom: 220px;
  z-index: 100;
  display: block;
}

.btn-circle{
  width: 50px;
  height: 50px;
  background-color:var(--dark);
  color: #fff;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.threeD-btn {
  display: block;
  margin: 0 auto;
  perspective: 300px;
  width: 50px;
  z-index: 100;
}

.threeD-btn > span {
  background: var(--yellow);
  border: none;
  box-shadow: 1px 73px 41px -22px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  display: block;
  transform-style: preserve-3d;
  transition: all 250ms ease-in;
  transform: rotateX(40deg);
  outline: 1px solid transparent;
  padding: 13px;
  position: relative;
  width: 100%;
  color:var(--dark);
}
.threeD-btn > span:after {
  background: #d09f06;
  content: "";
  height: 40%;
  left: 0;
  position: absolute;
  top: 100%;
  transform-origin: 0% 0%;
  transform: rotateX(-90deg);
  transition: all 250ms ease-in;
  width: 100%;
  z-index: -1;
}
.threeD-btn > span > span {
  display: block;
  height: 40px;
  position: relative;
  transform: scale(0.9);
  width: 40px;
}
.threeD-btn > span > span:before, .threeD-btn > span > span:after {
  content: "";
  border-radius: 2px;
  display: block;
  width: 85%;
  height: 25%;
  position: absolute;
  top: 37%;
  left: 8%;
  transition: 0.3s;
  background: black;
}
.threeD-btn > span > span:after {
  transform: rotate(90deg);
}
.threeD-btn > span:hover, .threeD-btn > span:focus {
  box-shadow: 1px 73px 41px -22px black;
  color: #8e6d05;
  transform: rotateX(30deg);
}
.threeD-btn > span:hover:after, .threeD-btn > span:focus:after {
  background: #b88d04;
}
.threeD-btn > span:hover > span:before, .threeD-btn > span:hover > span:after, .threeD-btn > span:focus > span:before, .threeD-btn > span:focus > span:after {
  width: 62%;
}
.threeD-btn > span:hover > span:before, .threeD-btn > span:focus > span:before {
  transform: rotate(180deg) translate(0, 0);
}
.threeD-btn > span:hover > span:after, .threeD-btn > span:focus > span:after {
  transform: rotate(-180deg) translate(-37.5%, 0);
}
.threeD-btn > span:active {
  transform: rotateX(20deg);
}
.threeD-btn > span:active > span:before,
.threeD-btn > span:active > span:after {
  background: skyblue;
}
.threeD-btn.active > span > span:before, .threeD-btn.active > span > span:after {
  width: 62%;
}
.threeD-btn.active > span > span:before {
  transform: rotate(180deg) translate(0, 0);
}
.threeD-btn.active > span > span:after {
  transform: rotate(-180deg) translate(-37.5%, 0);
}

.btn-block + .btn-block {
  margin-top: 0;
}

@media (max-width: 425px) {
  .btn-sm-block{
    display: block;
    width: 100%
  }

  .display-1 {
    font-size: 2rem !important;
  }

  .display-2 {
    font-size: 1.5rem !important;
  }
}

.badge {
  height: auto;
  color: #36220f;
  vertical-align: middle;
  font-weight: 600;
}

.badge-sm {
  font-size: .75em;
  padding: 0.4em;
}

.badge.easy {
  background: #70c381;
}

.badge.medium {
  background: #f57700;
}

.badge.difficult {
  background: #eb4454;
}

.badge.yellow {
  background: var(--yellow);
}

.badge-secondary {
  color: #fff !important;
  background-color: #2D2F35;
  font-size: 1em;
  font-weight: 400;
}

.badge.badge-dark{
  background: var(--gray-dark);
  color: #fff;
}

.difficulty-text.easy{

  color: #70c381;
}

.difficulty-text.medium{
  color: #f57700;
}

.difficulty-text.difficult{
  color: #eb4454;
}

.difficulty-text.easy{
  color: #70c381;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.dropdown-menu{
  font-size: 1em;
}

.dropdown-menu.show {
  max-height: 50vh;
  // width: 100%;
  overflow-y: scroll;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent;
}

.navbar-toggler {
  color: #fff;
  width: 60px;
}

.navbar>.container-fluid{
  max-width: 92%;
}

.card {
  border: 1px solid #434343;
  background: var(--gray-dark)
}

.card-body {
  padding: 1rem;
}

.card.light {
  background: #494848;
}

.card .img-container {
  position: relative;
  overflow: hidden;
  max-height: 150px;
}

.card .img-container::after {
  content: "";
  display: block;
  padding-top: 63%;
}

.card .img-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.card .img-container .badge {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  box-shadow: 3px 3px 11px 0 rgb(0 0 0 / 25%);
}

.card small {
  font-size: 85%;
}

a {
  color: #fff;
  transition: all 0.2s ease;
}

a:hover{
  color: #fff;
  opacity: .8;
}

.side-nav .list-group-item a.active {
  background: var(--dark);
  color: #ba8b00;
}

.side-nav-container::-webkit-scrollbar, .side-nav::-webkit-scrollbar, .list-group::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.side-nav .list-group-item.active a {
  background-color: var(--yellow);
  color: var(--dark);
  font-weight: 600;
}

.side-nav-container {
  // background: var(--dark);
  width: 100%;
  overflow: scroll;
}

.side-nav-container .list-group-item a {
  padding: 0;
  text-align: center;
  width: max-content;
  padding: 0.5em 0em;
  // color:var(--dark);
}

.side-nav {
  // max-width: 100%;
  overflow: scroll;
}

.side-nav ul .list-group-item {
  border-bottom: 1px solid #484848;
  padding: 1em;
}

.mobile-nav-trail .side-nav .list-group{
  flex-direction: row;
}

.mobile-nav-trail .side-nav ul .list-group-item {
  margin: 0;
  padding: 0.2em 0.5em;
  border: 1px solid #484848;
  border-radius: 0.25rem;
  margin-right: 1em;
}

.profile-nav .side-nav .list-group{
  flex-direction: column;
  width: 100%;
}

.profile-nav .mobile-nav-trail .side-nav ul .list-group-item{
  border: none;
}

.profile-nav .mobile-nav-trail .side-nav ul .list-group{
  width: 100%;
}

@media (max-width: 992px) {
  .profile-nav .side-nav .list-group{
    flex-direction: row;
  }

  .profile-nav .mobile-nav-trail .side-nav ul .list-group-item{
    border: 1px solid #484848;
  }
}

.horizontal-scroll::-webkit-scrollbar {

  display: none;  /* Safari and Chrome */
}

.horizontal-scroll{
  justify-content: center;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow-x: auto;
  flex-wrap: nowrap;

  .card{
    width:200px
  }
  .map-logo{
    width: 200px;
  }
}

@media (max-width: 768px) {

  .horizontal-scroll {
    justify-content: start;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  .horizontal-scroll .card, .horizontal-scroll .map-logo {
    flex: 0 0 auto; /* Prevent cards from shrinking and set them to their intrinsic width */
  }

  .horizontal-scroll .map-logo{
    width: 140px;
  }

}

.mobile-nav-trail .side-nav ul {
  display: inline-flex;
  // justify-content: space-around;
  flex-direction: row;
  // width: 100%;
  overflow: scroll;
}

// .side-nav ul .list-group-item span {
//   margin-right: 5px;
//   display: none;
// }

.text-col-count-2 {
  column-count: 2;
}

.modal-content {
  background-color: var(--dark);
  color: #fff;
  border-radius: 1rem;
}

.close {
  font-size: 3em;
  font-weight: 300;
  color: #fff;
  opacity: 1;
  text-shadow: none;
}

.modal{
  background-color: rgb(0 0 0 / 71%);
  overflow: scroll;
}

.modal-md{
  max-width: 580px !important;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.mapStyleThumbnail{
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.flagsStyleThumbnail{
  object-fit: cover;
  width: 40px;
  height: 30px;
}

.env-banner{
  position: fixed;
    bottom: 0;
    width: 100%;
    background: #eb4454;
    text-align: center;
    z-index: 100;
    font-size: 1.2em;
    font-weight: 600;
    color:var(--dark);
}

/* Keyframes for delaying the animation */
// @keyframes liftBanner {
//   0% {
//     opacity: 0;
//     transform: translateX(-50%) translateY(0);
//   }
//   100% {
//     opacity: 1;
//     transform: translateX(-50%) translateY(-100%);
//   }
// }

.sale-banner{
  background: rgb(28,162,191);
  background: linear-gradient(90deg, rgba(28,162,191,1) 1%, rgba(16,145,172,1) 27%, rgba(16,145,172,1) 40%, rgba(44,194,227,1) 100%);
  position: fixed;
  bottom: -220px;
  right: 20px;
  width: 410px;
  max-width: 88%;
  z-index: 10000000000;
  border-radius: 8px;
  transition: all 0.5s ease;

  .dashed-line{
    border: 2px dashed #05050525;
    border-radius: 8px;
  }

  .count-num{
    font-family: Bebas Neue, impact, sans-serif;
    font-size: 3em;
    margin: 0;
    max-height: 50px;
    line-height: 30px;
    margin-top: 10px;
  }

  .closeSale{
    background-color: #fff;
    color:var(--dark);
    border-radius: 100%;
    height: 28px;
    width: 28px;
    position: absolute;
    top: 7px;
    right: 7px;

    &:hover{
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .dark {
    color: rgb(0 0 0 / 68%);
  }

  &.active{
    // animation: liftBanner .5s forwards;
    bottom: 20px;
  }

  @media (max-width: 1024px) {
    width: 340px;
  }

  @media (max-width: 420px) {
    right: 0;
    left: 0;
    width: 93%;
    margin: auto !important;
  }
}

.announcement-banner{
  background: rgb(255,193,7);
  background: linear-gradient(90deg, rgba(255,193,7,1) 1%,  rgba(244,176,0,1) 100%);
  height: 4vh;

  .dark {
    color: rgb(0 0 0 / 68%);
  }
}

.color-ani {animation: col 3s linear infinite;}

@keyframes col {
0%,71% {fill:none}
72% {fill:black}
75%,100% {fill:none}
}


@media (min-width: 992px){
  .container {
    max-width: 1040px;
  }
}

@media (max-width: 768px) {

  section {
    margin-bottom: 4rem !important;
  }

  .lh-plus-mob {
    line-height: 2em;
  }

  .features .card {
    padding: 0 2em;
    height: 168px;
  }

  .container.bg-dark {
    background-color: transparent !important;
  }

  //.navbar-collapse {
  //  background:var(--dark);
  //  z-index: 1000;
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  padding: 2em 1em;
  //  height: 100%;
  //  box-shadow: 5px 5px 40px 7px rgb(0 0 0 / 26%);
  //  box-shadow: 5px 5px 40px 7px rgb(0 0 0 / 26%);
  //}
  //
  //.navbar-collapse.collapsing {
  //  height: 100%;
  //  left: -75%;
  //  transition: height 0s ease;
  //}

  //.navbar-collapse.show {
  //  height: 100%;
  //  left: 0;
  //  // transition: left 400ms ease-in-out;
  //  background: var(--dark);
  //  max-width: 100%;
  //  width: 70%;
  //}

  // .navbar-toggler.collapsed ~ .navbar-collapse {
  //   transition: left 0ms ease-in;
  // }

  .navbar-nav .nav-item {
    width: 100%;
    border-bottom: 1px solid #484646;
    padding: 1.25em 1em;
    margin: 0;
  }

  .navbar-toggler .material-icons {
    color: #989898;
    font-size: 1.6em;
  }

  .hidden-sm-down {
    display: none;
  }

  .hidden-sm-down {
    display: none;
  }

  .text-col-count-2 {
    column-count: 1;
  }
}

@media (max-width: 420px) {
  .side-nav-container, .side-nav, .list-group {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  hr.my-5{
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
}

.table{
  --bs-table-bg: transparent;
  --bs-table-color: #fff;
  --bs-table-border-color: var(--gray);
  --bs-emphasis-color: #fff;
}

/*  NAV  */

.nav-link{
  color: #fff;
  &:hover{
    color: #fff;
    opacity: .8;
  }
}

.navbar {
  height: 10vh;

  .badge-primary{
    position: absolute;
    top: 27px;
    right: 4px;
    background: #2dc2e3;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (max-width: 991px) {
    height: auto;
  }
}

.navbar-toggler {
  .material-icons {
    @media only screen and (min-width: 769px) and (max-width: 991px) {
      font-size: 2em;
      color: #fff;
    }
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color:var(--dark);
}

.nav-profile-img {
  width: 46px;
  height: 46px;
  float: left;
  margin-right: 14px;
  object-fit: cover;
  border-radius: 50%;
}

.promo-title {
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 0.75em;
  background: #63a971;
  background: linear-gradient(90deg, #70c381 0%, #63a971 100%);
  position: relative;
  overflow: hidden;

  .dark {
    color: rgb(0 0 0 / 68%);
  }

  img {
    position: absolute;
    top: 0;
    right: 5%;
    max-height: 140%;
  }

  a:hover {
    color: rgb(0 0 0 / 68%);
  }

  @media (max-width: 460px) {
    .dark {
      display: none;
      color: #fff;
    }

    img {
      display: none;
    }
  }
}

.heartbeat {
  animation: heartbeat 3s ease-in-out 3s infinite both;
  animation: heartbeat 3s ease-in-out 3s infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform: scale(1);
    transform-origin: center center;
    transform-origin: center center;
    animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    transform: scale(0.91);
    animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    transform: scale(0.98);
    animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    transform: scale(0.87);
    animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    transform: scale(1);
    animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.hero-search:focus-within {
  background-color: var(--gray-dark);
}

.hero-search .form-control:focus {
  box-shadow: none;
}

.hero-search {
  background-color: rgb(0 0 0 / 62%);
  border: none;
  transition: all 0.5s ease;
  border-radius: 44px;
}

.hero-search .form-control,
.hero-search .input-group-text {
  padding: 0.375rem 1.5rem 0.375rem 0;
  background: transparent;
  border: none;
  color: #fff;
}

.hero-image {
  // margin-bottom: 2em;
  // position: relative;
  // padding-bottom: 56.25%; /* 16:9 */
  // height: 0;
}

.hero-image iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.navbar .logo {
  width: 160px;
  max-width: 160px;
}

.navbar .sale-logo{
  width: 200px;
  max-width: 200px;
}

.btn-purple {
  background: #b44fc5;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgb(232 232 232 / 17.5%) !important;
}

@media (max-width: 500px) {
  .navbar {
    margin: 0;
    height: 11vh;
  }

  .navbar-toggler{
    margin: 0;
    padding: 0;
    width: auto;
  }
}

/* CUstom Forms */

.custom-select{
  background-color: var(--gray-dark);
  color: #fff !important;
  border: none !important;
  border-bottom: 1px solid #4f4f4f;
  border-radius: 0;
  //height: 2.5em;
  padding: 0;
}

.custom-select option{
  color: #fff;
}

#plannedTrailDate, #plannedTrailTime {
  padding: 0.3em 0.5em;
  border: none;
  border-radius: 5px;
  height: 3em;
  color: #fff;
  background-color: var(--gray-dark);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
  width: 100%;
}

#plannedTrailTime::-webkit-calendar-picker-indicator, #plannedTrailDate::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.8;
}

/* Style the arrow icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #555;
  font-size: 18px;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: var(--gray-dark);
  color: #8a8a8a;
  border: none;
  border-radius: 4px;
  width: 100%;
}

input[type="file"]::-webkit-file-upload-button {
  background-color: var(--gray-dark);
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 1em;
  border: 0;
  box-shadow: none;
}

input[type="file"]::-moz-file-upload-button {
  background-color: var(--gray-dark);
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 1em;
  border: 0;
  box-shadow: none;
}

input[type="file"]::-ms-browse {
  background-color: var(--gray-dark);
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 1em;
  border: 0;
  box-shadow: none;
}

input[type="file"]::file-selector-button {
  background-color: var(--gray-dark);
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 1em;
  border: 0;
  box-shadow: none;
}

//.custom-select {
//  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='grey' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
//  background-repeat: no-repeat;
//  background-size: 10px;
//  background-position: bottom 50% right 10px;
//  background-color: var(--gray-dark);
//  padding: 0.75em 1em;
//  border: none;
//}
//
//.custom-select-wrapper::after {
//  background-image: none;
//}

.custom-file-label {
  background: var(--gray-dark);
  border: none;
  color: #a6a6a6;
  height: 3em;
  display: flex !important;
  align-items: center !important;
}

.custom-file-label::after {
  height: 3em;
  display: flex !important;
  align-items: center !important;
}

/*   HOME   */

.hero header {
  padding-right: 3em;
  margin-bottom: 2em;

  @media only screen and (min-width: 769px) and (max-width: 991px) {
    padding-right: 0;
  }
}

.hero .title {
  position: absolute;
  bottom: 10%;
  right: 0;
  background: var(--yellow);
  padding: 10px 30px 10px 10px;
}

.hero .title:lang(en-ie) {
  background-color: red;
}

.hero .title p {
  padding: 0 5px;
  margin: 0;
  color:var(--dark);
}

.hero .app-screen {
  position: absolute;
  right: 0;
  bottom: -21%;
  max-width: 390px;
}

.hero .feature-video-cover-polyline {
  bottom: 10%;
  left: 0;
  width: 80%;
}

.hero .feature-video-cover-btn {
  bottom: 10%;
  left: 70%;
  width: 20%;
}

.hero .feature-video-cover-tag {
  bottom: 45%;
  left: 80%;
  width: 16%;
}

.hero .feature-video-cover-btn:hover {
  transform: scale(1.1);
}

.hero-feature-box {
  .material-icons {
    font-size: 3em;
  }

  a {
    width: 100%;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }

  img {
    width: 100%;
  }
}

.hero .bottom-text {
  position: absolute;
  bottom: 0;
  padding: 0 3em 1em;
  max-width: 100%;
  right: 0;
  left: 0;
  display: block;

  h2 {
    font-weight: 600;
    font-size: 1.25em;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

// Search results
.ui-autocomplete {
  background-color: var(--dark) !important;
}

.ui-menu {
  // margin-top: 57px !important;
  border-radius: 4px;
  max-height: 60vh;
  overflow: scroll;
}

.home .ui-autocomplete-input{
  padding: 15px 0 15px 15px !important;
}

.home .hero-search{
  padding: 0 !important;
}

.ui-autocomplete img {
  max-width: 87px;
  float: left;
  margin-right: 1em;
}

.ui-menu {
  width: 400px;
  margin-top: 10px;
}

.ui-menu .ui-menu-item {
  padding: 0.75em;
  border-bottom: 1px solid #404040;
  display: flex;
  margin-top: 0.5em !important;
}

// .ui-menu .ui-menu-item p{
//   display: none;
// }

.ui-menu .ui-menu-item-wrapper {
  width: 100%;
}

.hero-feature-box .hero {
  img {
    width: 100%;
  }
}

.hero img,
.hero .title {
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.hero ul.d-flex {
  padding-right: 3em;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-inner {
  height: 100%;
  display: flex;
  align-items: center;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  height: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;

  .card{
    background: rgba(23, 24, 29, 0.69);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: fit-content;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

//.carousel-inner {
//  height: 168px;
//
//  @media screen and (min-width: 768px) {
//    height: 260px;
//  }
//}

// .carousel-item {
//   max-height: 260px;
// }

.hero .carousel button {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.hero .middot:last-child {
  display: none;
}

// .home-stats{
//   background: url("../../img/map-lines.png") no-repeat center center;
//   background-size: cover;
// }

.stats-container {
  padding-right: 0;
  padding-left: 0;
  position: relative;
}

.home-stats-bg {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.download .banner {
  position: relative;
}

.download .banner img {
  max-height: 40vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  max-width: 100%;
  transition: all 1s ease;
  transition: all 1s ease;
  transition: all 1s ease;
  transition: all 1s ease;
}

.carousel-control-n t,
.carousel-control-prev {
  opacity: 1;
}

.local-trails {
  background: url("../img/nearby-trails.jpg") no-repeat center center fixed;
  background-size: cover;
  height: 30vh;
  cursor: pointer;
}

.local-trails .material-icons {
  font-size: 34px;
}

.app-screens {
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.app-screens img {
  width: 30%;
}

.slider-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 31%;
  background: rgb(36 0 0);
  background: linear-gradient(90deg, rgb(36 0 0 / 0%) 0%, rgb(32 32 32 / 100%) 100%);
}

.splide--nav>.splide__track>.splide__list>.splide__slide.is-active{
  border: none !important;
}

.splide__arrow {
  opacity: 1 !important;
  background: transparent !important;
}

.splide__arrow svg{
  fill: #fff !important;
  height: 2.5em !important;
  width: 2.5em !important;
}

.splide__pagination {
  bottom: -1em !important;
}

.splide__arrow--prev {
  left: -4em !important;
}

.splide__arrow--next {
  right: -4em !important;
}

.slider-gradient {
  display: none;
}

.nav-tabs .nav-link {
  border: none;
  padding: 0.5rem 1.5rem;
}

.nav-tabs {
  border-bottom: 1px solid #494848;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #fff;
  color: var(--yellow);
}

#features-slider img {
  max-width: 270px;
}

// .features a:hover {
//   color: var(--dark);
//   opacity: 0.4;
// }

.pricelist a:hover {
  color: #fff;
}

.features a:hover h5 {
  opacity: 0.6;
}

.features .demo {
  max-width: 70%;
}

.features .card {
  padding: 0 3em;
  height: 250px;
  flex-direction: row;
}

.features .card,
.pricelist .card {
  top: 0;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.features .card:hover,
.pricelist .card:hover {
  top: -8px;
}

.ethos .card {
  /* height: 300px; */
  background: url("../img/redesign/to-bg.jpeg") no-repeat center center;
  background-size: cover;
  background-size: cover;
  background-size: cover;
  background-size: cover;
}

.ethos .card h5 {
  margin: 2em;
  padding: 1em;
  background: rgb(0 0 0 / 50%);
}

.ethos .gif {
  max-width: 100px;
}

.card.yellow {
  background: rgb(255 170 0 / 100%);
  background: linear-gradient(90deg, rgb(255 170 0 / 100%) 0%, rgb(255 198 0 / 100%) 100%);
}

.card.green {
  background: #2da043;
  background: linear-gradient(127deg, #70c380, #2da043);
}

.card.blue {
  background: #2da043;
  background: linear-gradient(127deg, #2cc2e3, #0099cb 99%);
}

.card.orange {
  background: #ff9500;
  background: linear-gradient(127deg, #ff9500, #f57700);
}

.video-modal {
  background: rgb(255 255 255 / 83%);
}

.video-modal .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.video-modal .modal-body {
  position: relative;

  // padding-bottom: 56.25%; /* 16:9 */
  padding-bottom: 54.25%;
  height: 0;
}

.video-modal iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-modal .close {
  font-size: 3em;
  font-weight: 500;
  color:var(--dark);
  opacity: 1;
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  @media (max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}

@media (max-width: 1200px) {
  .hero .bottom-text h2 {
    font-size: 1em;
  }
}

@media (max-width: 1090px) {

  .slider-gradient {
    display: block;
  }

  .hero .app-screen {
    max-width: 320px;
  }

  .navbar {
    height: 10vh;
    // z-index: 10;
    // height: auto;
  }
}

@media (max-width: 992px) {
  .desktop-only {
    display: none !important;
  }

  .banner {
    display: none;
  }

  .display-3 {
    font-size: 2rem;
  }

  .local-trails {
    height: 17vh;
  }

  .features .demo {
    max-width: 50%;
  }
}

@media (max-width: 768px) {

  .home .nav-tabs .nav-link {
    width: 50%;
    text-align: center;
    padding: 0.5em;
  }

  .hero-feature-box {
    max-height: 9em;
    overflow: hidden;
  }

  /* .home .nav-tabs .nav-link h4{
    font-size: 1rem;
  } */

  .splide__arrow--prev {
    left: 0;
  }

  .splide__arrow--next {
    right: 0;
  }

  .hero {
    /* margin-top: 2em !important; */
  }

  .hero-image {
    margin-bottom: 2em;
  }

  .hero .app-screen {
    position: absolute;
    right: 0;
    bottom: -21%;
    max-width: 450px;
  }

  .hero .title {
    padding: 10px 9px 10px 10px;
  }

  .nav-tabs .nav-link{

  }

  .hero header {
    padding-right: 0;
  }

  .hero .badge {
    margin-top: 3em;
  }

  .features .card {
    padding: 0 2em;
    height: 168px;
  }

  .navbar {
    margin: 0;
  }

  .navbar .logo {
    max-width: 140px;
  }
}

@media (max-width: 420px) {
  .local-trails {
    height: 25vh;
    background-position: -37px -10px;
    background-size: 123%;
    background-attachment: scroll;
  }

  .hero .app-screen {
    max-width: 250px;
  }

  .ui-menu {
    top: 77px;
    left: 28px;
    width: 321px;
    display: block;
  }

  .ui-front {
    z-index: 110 !important;
  }

  .features .demo {
    max-width: 70%;
  }
}

/* TRAIL OVERVIEW */

.trail-card{
  .img-container{
    max-height: 232px;
    overflow: hidden;
    border: 2px solid #434343;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;

    .gradient{
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index: 0;
      background: rgb(23,24,29);
      background: -moz-linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      background: -webkit-linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      background: linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      pointer-events: none;
    }

    .badge{
      position: absolute;
      top:0;
      left:0;
    }

    .trail-details{
      z-index: 2;
      position: absolute;
      bottom:0;
      left:0;
      width: 100%;
    }
  }
}

.mobile-nav-trail{
  position: relative;
  // height: 6vh;
}

.mobile-nav-trail .side-nav {
  overflow: visible;
}

.mobile-nav-trail .continue, .trail-map-buttons .continue{
  background: rgb(32,32,32);
  background: -moz-linear-gradient(270deg, rgba(32,32,32,1) 0%, rgba(32,32,32,0) 100%);
  background: -webkit-linear-gradient(270deg, rgba(32,32,32,1) 0%, rgba(32,32,32,0) 100%);
  background: linear-gradient(270deg, rgba(32,32,32,1) 0%, rgba(32,32,32,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="--dark",endColorstr="--dark",GradientType=1);
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  margin: 0;
  height: 100%;
  width: 15%;
  display: none !important;
}

.profile-nav .mobile-nav-trail .continue{
  background: rgb(27,27,27);
  background: -moz-linear-gradient(270deg, rgba(27,27,27,1) 0%, rgba(27,27,27,0) 100%);
  background: -webkit-linear-gradient(270deg, rgba(27,27,27,1) 0%, rgba(27,27,27,0) 100%);
  background: linear-gradient(270deg, rgba(27,27,27,1) 0%, rgba(27,27,27,0) 100%);
}

.trail{
  overflow: hidden;

  .badge{
    width: fit-content;
  }
}

#trail_description{
  h4{
    margin-top: 1em;
    font-size: 1em !important;
    font-weight: 700;
  }

  h3{
    margin-top: 1em;
    font-size: 1.25em !important;
    font-weight: 700;
    color: var(--yellow);
  }
}

.min-height-100{
  min-height: 100vh;
}

#trailImageGallery{
  overflow: scroll;

  .modal-dialog {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .modal-content {
    height: auto;
    min-height: 100vh;
    border-radius: 0;
  }

  .modal-body{
    padding: 0;
  }

  .splide__slide img {
    width: auto;
    margin: 2em auto;
    max-height: 70vh;
    object-fit: contain;
  }

  .splide__arrow--prev {
    left: 1em !important;
  }

  .splide__arrow--next {
    right: 1em !important;
  }
}

.trail-topbar .share-buttons {
  padding: 0;
}

.trail-topbar svg {
  width: 16px;
  fill: #868686;
  margin-left: 1em;
}

.trail-topbar .breadcrumb {
  padding: 0;
  margin: 0;
}

.trail-details{
  z-index:2;
}

.btn-photo{
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 2;
}

.trail-bottom-sheet-mobile{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto;
  background: #17181d;
  border-top: 2px solid #2f3034;
  z-index: 100;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}

/* Custom styles for bottom sheet */

#continueModal{
  z-index: 10000000000000;
}

.modal-bottom-sheet {
  //display: flex;
  //align-items: end;
}

.modal-bottom-sheet .modal-dialog {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-end !important;
}

.bottom-sheet-content {
  padding: 20px;
}

.trail-qrcode{
  position: fixed;
  top: 12vh;
  right: 0;
  background: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: .5em;
  width: 120px;
  height: 166px;
  z-index: 100;

  small{
    color: var(--dark);
    font-size: 10px;
    line-height: 13px;
    display: block;
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

@media (max-width: 768px) {
  .btn-photo{
    right: 1em;
    bottom: unset;
    top: 1em
  }
}

.trail{

  .trail .square{
    max-width: 80px;
    max-height: 80px;
  }

  .trail .display-4 .badge{
    padding: 0.5rem 0.75rem;
    font-size: .75rem;
  }

  .trail h1{
    font-size: 1.5em;
    line-height: 1.4;
  }

  .img-container{
    height: 292px;
    overflow: hidden;
    border: 2px solid #434343;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;

    .bg-img{
      position: absolute; /* Positions the image absolutely within its container */
      top: 0;
      left: 0;
      width: 100%; /* Ensures the image covers the full width of its container */
      height: 100%; /* Ensures the image covers the full height of its container */
      object-fit: cover; /* Ensures the image covers the container without distorting its aspect ratio */
      object-position: center; /* Centers the image */
      z-index: -1; /* Ensures the image stays behind other content in the container */
    }

    .gradient{
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index: 0;
      background: rgb(23,24,29);
      background: -moz-linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      background: -webkit-linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      background: linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
    }
  }

  @media (max-width: 992px) {
    .border-right{
      border-right: none !important;
    }
  }
}

.park{
  .display-4 .badge{
    padding: 0.5rem 0.75rem;
    font-size: .75rem;
  }

  h1{
    font-size: 1.5em;
    line-height: 1.4;
  }

  .img-container{
    height: 50vh;
    overflow: hidden;
    border: 2px solid #434343;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;

    .bg-img{
      position: absolute; /* Positions the image absolutely within its container */
      top: 0;
      left: 0;
      width: 100%; /* Ensures the image covers the full width of its container */
      height: 100%; /* Ensures the image covers the full height of its container */
      object-fit: cover; /* Ensures the image covers the container without distorting its aspect ratio */
      object-position: center; /* Centers the image */
      z-index: -1; /* Ensures the image stays behind other content in the container */
    }

    .gradient{
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index: 0;
      background: rgb(23,24,29);
      background: -moz-linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      background: -webkit-linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
      background: linear-gradient(180deg, rgba(23,24,29,0) 0%, rgba(23,24,29, .85) 100%);
    }
  }
}

// .detail-location .material-icons {
//   font-size: 16px;
//   margin-right: 5px;
// }

#park-description-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: max-height 0.5s ease-in-out;
  max-height: 4.5em; /* 3 lines (adjust for line-height) */
  line-height: 1.5em;
}

#park-description-text.expanded {
  max-height: none; /* No height limit when expanded */
  -webkit-line-clamp: inherit;
}

#park-read-more {
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}

#readmore {
  line-height: 1.5;
}

#readmore #trail_description.collapse {
  display: block;
  overflow: hidden;
  margin-bottom: 1em;
}

#readmore #trail_description.collapse:not(.show) {
  display: block;
  height: 9ex;
  overflow: hidden;
  margin-bottom: 1em;
}

#readmore #trail_description.collapsing {
  height: 13ex;
  margin-bottom: 1em;
}

#readmore a[role="button"].collapsed::after {
  content: "Read more";
  font-weight: normal;
}

#readmore a[role="button"]:not(.collapsed)::after {
  content: "Read less";
}

.list-group-item {
  padding: 0;
  margin-bottom: 0.5em;
}

.list-group-item a {
  padding: 1rem 1.25rem;
}

.list-group-item:hover {
  background-color: var(--dark);
}

.list-group-item.active {
  border-radius: 0.25rem;
}

.list-group-item.active:hover {
  border-radius: 0.25rem;
  background-color: var(--yellow);
}

.rating span {
  width: 25px;
}

.rating span small {
  font-size: 18px;
}

.list-group-item,
.list-group-item:hover {
  background-color: transparent;
  border: none;
}

.trix-content a {
  color: var(--yellow);
}

.featured-review .thumbnailContainer{
  aspect-ratio: 4 / 3;

  img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.iframePreview{
  width: 100%;
  aspect-ratio: 4/3;
}

#iframeModal{
  overflow-y: scroll;
}

// #iframeModal .modal-body{
//   height: 80vh;
//   overflow-y: auto;
// }

@media (max-width: 420px) {
  .trail-actions button {
    display: block;
    width: 100%;
    margin-bottom: 1em;
  }

  // .mobile-nav-trail .side-nav {
  //   overflow: visible;
  // }

  .dropup .dropdown-menu {
    padding: 0.5em;
  }

  .country-mobile-nav .list-group-item {
    width: auto !important;
  }
}

@media (max-width: 992px) {

  .mobile-nav-trail .continue, .trail-map-buttons .continue{
    display: flex !important;
  }

  .explore .trail-map-buttons .continue{
    display: none !important;
  }

}

/* --------- TRAIL SIDE NAV --------- */

.breadcrumb {
  color: var(--yellow);
  background: transparent;
}

.breadcrumb a {
  text-transform: capitalize;
  color: #898989 !important;
}

.side-nav .list-group-item.active {
  background-color: var(--dark);
  border-color: transparent;
}

@media (max-width: 768px) {

  .side-nav-container .right-caret {
    font-size: 40px;
    display: flex;
    align-items: flex-end;
    padding-left: 30px;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 90px;
    background: rgb(255 255 255);
    background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(32 32 32 / 100%) 87%);
    background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(32 32 32 / 100%) 87%);
    background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(32 32 32 / 100%) 87%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr="#ffffff", endColorstr="--dark", GradientType=1);
  }
}

/* --------- TRAIL REVIEWS --------- */

.text-black {
  color: #000;
}

.restaurant-detailed-header-left img {
  width: 88px;
  height: 88px;
  border-radius: 3px;
  object-fit: cover;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 7.5%) !important;
}

.reviews-members .media .mr-3 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}

.reviews-members-footer span {
  font-size: 1.5em;
}

.reviews-members #readmore #trail_description.collapse:not(.show) {
  height: 3em;
}

.reviews-members #readmore #trail_description.collapsing {
  height: 3rem;
}

.vuelightbox__image img {
  width: auto !important;
  height: 100% !important;
  max-height: 100vh;
  max-width: 100%;
}

/* --------- PROFILES --------- */

.profile-empty-list{
  background: url("../img/empty-list-bg.png") no-repeat center center;
}


#copyURLInputGroup{
  height: 3em;
}

#trailShareInput{
  //height: inherit;
  background: var(--gray-dark);
  border: none;
}

.input-group-append .input-group-text{
  border: none;
  color: #ffffff;
}

/* --------- COUNTRIES / REGIONS --------- */

.trail-filter .dropdown {
  display: inline-block;
  margin-right: 1em;
}

.country-gallery-container{

  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    // max-height: 250px;
    // max-height: 590px;

      img{
        aspect-ratio:4/3;
      }
    }

    .img-fluid{
      object-fit: cover;
      vertical-align: bottom;
      height: 100% !important;
      width: 100% !important;
      padding: 5px;
      border-radius: 8px;
    }

    .img0 { grid-area: 1 / 1 / 3 / 3; }
    .img1 { grid-area: 1 / 3 / 2 / 4; }
    .img2 { grid-area: 1 / 4 / 2 / 5; }
    .img3 { grid-area: 2 / 3 / 3 / 4; }
    .img4 { grid-area: 2 / 4 / 3 / 5; }
}

.country{
  .accordion{
    .btn-link .material-icons {
      transform: rotate(0deg);
      transition: transform 0.3s ease-in-out;
    }
    .btn-link:not(.collapsed) .material-icons {
      transform: rotate(45deg);
    }

    .collapse{
      overflow: auto;
    }

    img{
      float: left;
      margin: 1em 2em 1em 0;

      @media (max-width: 420px) {
        text-align: left;
        float: none;
        width: 50%;
        height: auto;
        display: block;
      }
    }
  }
}

.big-list{
  img{
    aspect-ratio: 1/1;
    object-fit: cover;
    vertical-align: bottom;
    -webkit-box-shadow: -27px 10px 37px -32px rgba(0,0,0,0.78);
    -moz-box-shadow: -27px 10px 37px -32px rgba(0,0,0,0.78);
    box-shadow: -27px 10px 37px -32px rgba(0,0,0,0.78);
  }
  .rubik-mono{
    font-family: "Rubik Mono One", impact, sans-serif;
    font-size: 76px;
    position: absolute;
    right: -17px;
    top: -21px;
    letter-spacing: -6px;
    z-index: 100;

    @media (max-width: 768px) {
      font-size: 36px;
      right: unset;
      left: 13px;
      top: -11px;
    }
  }

}

@media (max-width: 768px) {
  .scrollable-container {
    position: relative;
  }

  .scrollable-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0),var(--dark));
    pointer-events: none;
  }

  .btn-group-scrollable {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }

  .btn-group-scrollable .btn {
    display: inline-block;
  }

  .btn-group-scrollable::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}

@media (max-width: 420px){
  .country-gallery-container{
    .image-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
      max-height: 590px;

      .img0 { grid-area: 1 / 1 / 3 / 3; }
      .img1 { grid-area: 3 / 1 / 4 / 2; }
      .img2 { grid-area: 3 / 2 / 4 / 3; }
      .img3 { grid-area: 4 / 1 / 5 / 2; }
      .img4 { grid-area: 4 / 2 / 5 / 3; }
    }
  }
}


/* --------- LOGIN --------- */

.firebaseui-container {
  background: transparent;
  box-shadow: none;
  // border: 1px solid #3c3c3c;

  /* color: #fff !important; */
  font: inherit;
}

.firebaseui-title,
.firebaseui-input,
.firebaseui-input-invalid,
.firebaseui-tos,
.firebaseui-label,
.firebaseui-text {
  color: #fff;
}

.firebaseui-text {
  font-size: 1em;
}

.firebaseui-textfield.mdl-textfield .firebaseui-input {
  border-color: #fff;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: var(--yellow);
}

.mdl-button--primary.mdl-button--primary,
.firebaseui-link {
  color: #c5c5c5;
}

.mdl-button--raised.mdl-button--colored,
.mdl-button--raised.mdl-button--colored:focus:not(:active),
.mdl-button--raised.mdl-button--colored:hover {
  background-color: var(--yellow);
  color: #6a4f0e;
}

.firebaseui-title {
  font-size: 1em;
  font-weight: bold;
}

.firebaseui-subtitle {
  font-size: 1em;
  color: var(--yellow);
}

/* --------- APP PAGE --------- */

.video-container {
  position: relative;

  // padding-bottom: 56.25%; /* 16:9 */
  padding-bottom: 54.25%;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#new-feature-slider {
  background: var(--dark);
  padding: 2em;
  border-radius: 8px;
}

// .splide--ttb > .splide__arrows .splide__arrow--prev,
// .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
//   top: -4em;
// }

// .splide--ttb > .splide__arrows .splide__arrow--next,
// .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
//   top: auto;
//   bottom: -4em;
// }

.splide--ttb > .splide__pagination {
  right: 1em;
}

.features img,
.new-features-mobile img {
  width: 80%;
}

@media (max-width: 576px) {
  .features img,
  .new-features-mobile img {
    width: 50%;
  }
}

/* --------- PURCHASES --------- */

.purchasing-container{
  .nav-link{
    padding: 2px;
    margin: 6px;

    @media(max-width: 991px){
      width: 28%;
    }
  }

  .nav-link.active{
    background: var(--yellow);
  }
}

.purchase-bg {
  width: 100%;
  height: auto;
}

#nav-pricing-tab.active{
  color: #2CC2E3;
}

.pricing__check-icon svg {
  width: 25px;
  height: 25px;
  margin-top: 2px;
  display: inline;
  fill: currentcolor;
}

.purchase-card{
  border: 1px solid #2CC2E3;
  border-radius: 8px;
  background-color: transparent;
  transition: all 0.2s ease;
  position: relative;
  padding: 1.5em;
}

.purchase-card:hover{
  background-color: var(--dark);
  cursor: pointer;
}

.purchase-card .badge{
  position: absolute;
  font-size: .75em;
  padding: .75em;
  top: -1.5em;
  left: 2em;
  width: auto;
}

.purchase-circle{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #3D3C3C;
  color: #2CC2E3;
}

.purchase-circle span{
  font-size: 32px !important
}


.purchases .sale-badge {
  width: 120px;
  position: absolute;
  top: -49px;
  right: 0;
  left: 0;
  margin: auto;
}

.sale-hide {
  display: block;
}

.sale-show {
  display: none;
}

.sale-mode .sale-show {
  display: block;
}

.sale-mode .sale-hide {
  display: none;
}

.purchases .form-check {
  border-bottom: 1px solid var(--gray-dark);
  display: flex;
  align-items: center;
  padding-left: 0;
  justify-content: space-between;

  label {
    width: 100%;
  }

  input {
    height: 20px;
    width: 20px;
    position: relative;
    margin: 0;
    float: right;
  }
}

.purchases .form-check:last-of-type {
  border: none;
}

.text-green.btn-link:hover {
  color: #57a166;
}

#purchase-preview-slider .card{
  height: 100%;
}
#purchase-preview-slider .card-body{
  background: #fff;
}

.unlock-card:hover{
  /* Locked Hover */
  .lock:before {
    height: 15px;
  }
}

.unlock-offer{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scale: 1.2;
  width: 100px;

  .unlock-container {
    display: flex;
    align-items: center;
    justify-content: center;
    //min-height: 400px;
  }
  .lock {
    width: 24px;
    height: 21px;
    border: 3px solid var(--locked-color);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .lock:after {
    content: "";
    display: block;
    background: var(--locked-color);
    width: 3px;
    height: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5px 0 0 -2px;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .lock:before {
    content: "";
    display: block;
    width: 15px;
    height: 12px;
    bottom: 100%;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border: 3px solid var(--locked-color);
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom: 0;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  /* Unlocked */
  .unlocked {
    transform: rotate(10deg);
  }
  .unlocked:before {
    bottom: 130%;
    left: 31%;
    margin-left: -11.5px;
    transform: rotate(-45deg);
  }
  .unlocked,
  .unlocked:before {
    border-color: var(--yellow);
  }
  .unlocked:after {
    background: var(--yellow);
  }
  /* Unlocked Hover */
  .unlocked:hover {
    transform: rotate(3deg);
  }
  .unlocked:hover:before {
    height: 10px;
    left: 40%;
    bottom: 124%;
    transform: rotate(-30deg);
  }
}

@media (max-width: 992px) {
  .purchases .sale-badge {
    width: 100px;
    position: absolute;
    top: -34px;
    right: unset;
    left: 10px;
    margin: auto;
  }
}

@media (max-width: 768px) {

  .purchases .display-4 {
    font-size: 1rem !important;
  }

  .purchase-mt-xs-3 {
    margin-top: 2em;
  }

  .purchase-card{
    margin: 0;
  }
}

/* --------- COMPETITION --------- */

.enquiry ul.primary {
  margin-top: 10px;
  padding: 1em;
  background: none;
  border-radius: 6px;
}

/* --------- TRAIL ANGELS --------- */
.enquiry ul {
  margin-top: 10px;
  padding: 1em;
  background: #db2b35;
  border-radius: 6px;
}

.enquiry li {
  margin-left: 14px;
}

/* --------- PARTNERSHIPS --------- */

#partnership-slider,
#business-slider {
  background-color: var(--dark);
  padding: 1em;
}

#partnership-slider li img,
#business-slider li img {
  max-height: 100px;
  margin: 0 auto;
  display: block;
}

.partnership-grid img {
  max-height: 140px;
}

.partnership-grid .fixHeight {
  height: 160px;
  display: flex;
  align-items: center;

  /* align-content: center; */
  justify-content: center;
  background: #fff;
  padding: 1em;
  border-radius: 8px;
}

/* --------- TRAIL GPT --------- */

.trail-gpt-header{
  margin-top: -200px;
}

.trail-gpt-headline{
  margin-top: -160px;
}

@media (max-width: 576px) {

  .trail-gpt-headline{
    margin-top: -110px;
  }

  .header{
    margin-top: -160px;
  }
}

@media (max-width: 420px) {
  .trail-gpt-header{
    margin-top: -130px;
  }

  .trail-gpt-headline{
    margin-top: -80px;
  }
}

/* --------- FOOTER --------- */

.footer {
  a.text-muted {
    display: block;
    padding: 0.5rem 0;
  }
}

footer {
  background-color: var(--dark);
}

// footer .list-group-item a {
//   padding: 0;
// }

footer svg {
  width: 24px;
  height: 24px;
  fill: rgb(255 255 255 / 50%);
}

.d-hotline{
  scale: .7 !important;
}

#fc_frame,
#fc_frame.fc-widget-normal {
  bottom: 85px !important;
}

@media (max-width: 992px) {
  .stats-container h3 {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  footer .logo {
    width: 60%;

    // margin: 0 auto;
    margin-bottom: 1em;
  }

  footer svg {
    width: 34px;
    height: 34px;
    fill: rgb(255 255 255 / 50%);
  }
}

/* --------- PRIVACY POLICY AND T'S --------- */

.page-tabs {
  margin: 2rem;
}

.languages {
  margin: 2rem;
}

.legal-tabs {
  margin: 44px 0;
  margin-block-start: 0;
  padding-inline-start: 0;

  li {
    display: inline-block;
    text-align: center;
    border: 1px #fff solid;
    margin: 4px 14px 0 0;
    padding: 10px;
    border-radius: 6px;

    a {
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: Apercu-Light, sans-serif;
    }
  }
}

.lang-btn {
  cursor: pointer;
}

.lang-btn-active {
  //@extend %apercu-light;
  background-color: #70c381;
  color: #fff !important;
}

.policy {
  p,
  li {
    font-size: 1em;
    line-height: 1.5rem;

    // margin-bottom: 10px;
  }

  h1 {
    margin-bottom: 22px;
    font-size: 1.75em;
    line-height: 2.125em;
    font-weight: bold;
  }

  h2 {
    margin-top: 2em;
    font-size: 1.25em;
    line-height: 1.75em;
    font-weight: bold;
  }

  h3,
  h4 {
    margin-top: 22px;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: bold;
  }

  h4 {
    color: var(--yellow);
  }

  li {
    margin-top: 22px;
  }

  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }
}

/* --------- MAP GLOBAL --------- */

// .mapbox{
//   height: 92vh;
// }

.weather-tooltip {
  z-index: 10000000 !important;
  padding: .5em !important;
  background: #fff !important;
}

.button-container{
  button{
    font-size: .75em !important;
  }
}

.map-content{
  background: #4d6c8d;
}

.map-title{
  position: absolute;
  background-color: rgba(32, 32, 32, 0.73333);
  border-radius: 5px;
  top: 21vh;
  left: 1em;
  z-index: 10;
  padding: 0.75rem;
  font-size: .875rem;
  max-width: 390px;
}

.map-title-trail{
  top: 17vh;
}

.sidebar-search {
  background-color: transparent;
  border: none;
  transition: all 0.5s ease;
  font-size: 1em;
}

.sidebar-search::placeholder {
  color: rgb(202 202 202);
}

.vbt-autcomplete-list {
  max-height: 350px;
  overflow-y: scroll;
  background: #fff !important;
  border-radius: 0;
  margin: 1em 0;
  padding: 0;
}

.vbst-item {
  padding: 1em;
  margin: 0;
}

.vbst-item.active:hover {
  background-color: #eee;
  color:var(--dark);
}

.tooltip {
  background-color: var(--yellow);
  color:var(--dark);
  padding: 15px;
  margin-right: 1em;
  margin-top: 10px;
  border-radius: 4px;
  font-weight: 500;
  z-index: 2;
}

.trail-map-buttons{
  display: flex;
  top: 20px;
  right: 2%;
  z-index: 600;

  .btn{
    border: none;
    overflow: hidden;
    background-color: rgba(17, 17, 17, 0.8);
    padding: .875em 1.2em;

    &:hover{
      background-color: rgba(17, 17, 17, 1);
    }
  }

  .drop-icon{
    font-size: 24px
  }

  .btn-success{
    .icon-container{
      background: var(--yellow);
      -webkit-transition: .15s ease-in-out;
      transition: all .15s ease-in-out;
    }

    &:hover{
      color:#fff;
    }
  }

  .btn-danger{
    .icon-container{
      background: #bb2f2f;
      -webkit-transition: .15s ease-in-out;
      transition: all .15s ease-in-out;
    }
  }
}

.trail-map-buttons::-webkit-scrollbar, .button-container::-webkit-scrollbar {
  display: none;
}

.iframe-container{

  .coordsbar{
    display: none;
  }

  .modal{
    background-color: rgba(7, 7, 7, 0.86) !important;
  }

  .iframe-header{
    padding: 1em 2em;

    img{
      width: 134px
    }
  }
}


@media (max-width: 1300px) {
  .trail-map-buttons{
    overflow-x: scroll;
  }

  .map-title{
    top: unset;
    bottom: 0px;
  }
}

@media (max-width: 600px){
  .map-title{
    display: none;
  }
}

@media (max-width: 768px){
  .map-title-trail{
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 5vh;
    margin: 0;
    border-radius: 0;
    background:var(--dark);
  }
}

@media (max-width: 500px) {

  .iframe-container{

    #line-chart{
      height: 100px !important;
      background: red;
    }

    .iframe-header{
      padding: 0.5em 0;
      font-size: 75%;
    }

    .chart-div{
      display: none;
    }

    .trail-map-buttons{
      display: none;
    }

    .iframe-footer{
      display: none;
    }

    .mapboxgl-ctrl-bottom-left{
      display: none;
    }

    .mapboxgl-map{
      height: 82vh !important;
    }
  }

}



/* RANGE SLIDER STYLING */

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  background: #d3d3d3;
  outline: none;
  // opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  background: #70c381;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #70c381;
  cursor: pointer;
}

.map-sidebar {
  .sidebar-container {
    position: relative;
    overflow-y: scroll;
    max-height: 100vh;
    max-width: 100%;
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  .toggle-container {
    padding: 20px;
    height: calc(100vh - 72px);
    overflow: scroll;
    width: 350px;
  }

  .col {
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .section-icon {
    /* margin-left: 8px; */
    margin-top: 2px;
    width: 25px;
    object-fit: contain;
    height: 25px;
  }

  h5 {
    font-weight: bold;
    padding: 0.5em 0 0 1.2em;
  }

  .s4 {
    flex: 5;
    text-align: left;
  }

  h5 {
    padding: 0;
  }

  .icon {
    float: right;
    margin-right: 16px;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentcolor;
  }

  .toggle-header {
    margin: 0;
    margin-top: 12px;
  }

  .measure-detail-icon {
    width: 15px;
    height: 20px;
    fill: -webkit-link;
    position: relative;
    right: 16px;
    cursor: pointer;
  }

  button{
    font-size: 0.75em !important;
  }
}

.like {
  display: block;
  float: left;
}

.nav-item {
  margin: .75em;
  // text-align: center;
}

img.profile-mask {
  object-fit: cover;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

@media (max-width: 1100px) {
  .nav-item {
    margin: .3em;
    // text-align: center;
  }
}

@media (max-width: 1024px) {
  .nav-item {
    .hero-search{
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .side-nav .list-group-item a.active {
    background: transparent !important;
  }

  .side-nav .list-group-item a.active .material-icons {
    color: #ba8b00;
  }
}

// images loaded by lozad flash a broken image before loading
// this is suppose to fix it
// img:not([src]) {
//   visibility: hidden;
// }

.modal-header {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  color: #f8f9fa;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  // background-color: #70c381;
}

.modal-body {
  // background-color: white;
}

// HR MID

.hrdivider {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.hrdivider .hrtext {
  position: absolute;
  top: -11px;
  left: 0;
  background:var(--dark);
  padding: 0 30px 0 0;
  font-size: 12px
}

// SWITCH

$sw-height: 30px;
$sw-width: 51px;

input{
  &.sw{
    opacity: 0; position:absolute; left: -9999px;
    & + label{
      user-select: none;
      transition: .2s ease;
      display: inline-block;
      height: $sw-height;
      width: $sw-width;
      position:relative;
      box-shadow: inset 0 0 0px 2px #616161;
      border-radius: 60px;
      &:before{
        content: "";
        position: absolute;
        display: block;
        height: $sw-height;
        width: $sw-height;
        top: 0;
        left: 0;
        border-radius: $sw-height/2;
        background: rgba(76,217,100,0);
        transition: .2s cubic-bezier(.24,0,.5,1);
      }

      /* White toggle */
      &:after {
        content: "";
        position: absolute;
        display: block;
        height: 28px;
        width: 28px;
        top: 50%;
        margin-top:-14px;
        left: 1px;
        border-radius: 60px;
        background: #fff;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0,0%,0%,.04), 0 4px 9px hsla(0,0%,0%,.13), 0 3px 3px hsla(0,0%,0%,.05);
        transition: .35s cubic-bezier(.54,1.60,.5,1);
        }
      span{
        white-space:nowrap;
        height:$sw-height;
        line-height:$sw-height;
        margin-left: $sw-width;
        padding-left:16px;
      }
    }
    &:checked{
      & + label:before {
        width: $sw-width;
        background: #70c381;
        transition: width .2s cubic-bezier(0,0,0,.1) !important;
      }

      & + label:after{
        left: $sw-width - $sw-height + 1;
      }

      & + label{
        box-shadow: inset 0 0 0px 25px #e4e4e4;
        transition: box-shadow 2.5s cubic-bezier(0,1.2,.94,.95)}
    }
  }
}

/* --------- TOURS --------- */

.tour-form option{
  padding: 10px;
  border-radius: 4px;
  margin: 5px;
}


.tour-provider{
  .thumbnail-container {
    position: relative;
    overflow: hidden;
    aspect-ratio: 2/1;
  }

  .thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container */
    object-position: center; /* Center the image */
  }
}

/* --------- CUSTOM LANDING PAGES --------- */

.custom-landing-page{

  .min-height-90{
    min-height: 90vh;
  }

  .content-container{
    width: 65%;
    z-index: 1;
  }

  .left-col{
    background-image: url("../img/modal-bg-purchase-pro-plus.webp");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
  }

  .group-image-container{
    max-width: 200px;
    background: #fff;
  }

  .login-buttons{
    max-width: 60%;
    margin: 0 auto;
  }

  .app-demo{
    max-width: 100%;
    width: 280px;
    margin: 0 auto;
    display: block;
  }

  @media (max-width: 1200px) {

    .left-col{
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  @media (max-width: 992px) {
    .content-container{
      width: 100%;
      padding: 1.5em;
    }
  }

  @media (max-width: 769px) {
    .right-col{
      order: -1;
      padding: 3em;
    }
  }

  @media (max-width: 420px) {
    .content-container{
      width: 100%;
    }

    .left-col {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .right-col{
      padding: 3em 0;
    }

  }
}


/* --------- MAP PROVIDERS --------- */

.map-provider-list{
  .card-header{
    background-color: #fff;

    img{
      max-height: 60px;
      margin: 0 auto;
      display: block;
    }
  }
}

.map-provider-details{
  .provider-logo{
    background: #fff;
  }

  .badge{
    position: absolute;
    top: 1em;
    left: 2em;
    z-index: 1;
  }
}

@media (max-width: 576px) {
  .covered-trail{
    .square{
      aspect-ratio: 2/1;
    }
  }
}
