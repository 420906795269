.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #fff;
  text-decoration: none
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .25)
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  box-shadow: none
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125)
}

.btn:not(:disabled):not(.disabled).active:focus, .btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .25), inset 0 3px 5px rgba(32, 32, 32, .125)
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-primary:hover {
  color: var(--dark);
  background-color: #eed207;
  border-color: #eed207
}

.btn-primary.focus, .btn-primary:focus {
  color: var(--dark);
  background-color: #eed207;
  border-color: #eed207;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(223, 171, 13, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow)
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: var(--dark);
  background-color: #eed207;
  border-color: #c69500
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(223, 171, 13, .5)
}

.btn-secondary {
  color: #fff;
  background-color: var(--gray-dark);
  border-color: var(--gray-dark);
  // box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #5a6268
}

.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #5a6268;
  // box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  // box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-success {
  color: var(--dark);
  background-color: var(--green);
  border-color: var(--green);
  // box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-success:hover {
  color: #fff;
  background-color: #55b869;
  border-color: #4cb461
}

.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #55b869;
  border-color: #4cb461;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(102, 172, 116, .5)
}

.btn-success.disabled, .btn-success:disabled {
  color: var(--dark);
  background-color: var(--green);
  border-color: var(--green)
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4cb461;
  border-color: #48ab5c
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(102, 172, 116, .5)
}

.btn-info {
  color: var(--dark);
  background-color: var(--blue);
  border-color: var(--blue);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-info:hover {
  color: #fff;
  background-color: #1badcd;
  border-color: #1aa4c2
}

.btn-info.focus, .btn-info:focus {
  color: #fff;
  background-color: #1badcd;
  border-color: #1aa4c2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(44, 172, 200, .5)
}

.btn-info.disabled, .btn-info:disabled {
  color: var(--dark);
  background-color: var(--blue);
  border-color: var(--blue)
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1aa4c2;
  border-color: #189ab7
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(44, 172, 200, .5)
}

.btn-warning {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-warning:hover {
  color: var(--dark);
  background-color: #eed207;
  border-color: #eed207
}

.btn-warning.focus, .btn-warning:focus {
  color: var(--dark);
  background-color: #eed207;
  border-color: #eed207;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(223, 171, 13, .5)
}

.btn-warning.disabled, .btn-warning:disabled {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow)
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: var(--dark);
  background-color: #eed207;
  border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(223, 171, 13, .5)
}

.btn-danger {
  color: #fff;
  background-color: #eb4454;
  border-color: #eb4454;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-danger:hover {
  color: #fff;
  background-color: #e72134;
  border-color: #e4182c
}

.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #e72134;
  border-color: #e4182c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(238, 96, 110, .5)
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #eb4454;
  border-color: #eb4454
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e4182c;
  border-color: #d8172a
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(238, 96, 110, .5)
}

.btn-light {
  color: var(--dark);
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-light:hover {
  color: var(--dark);
  background-color: #e2e6ea;
  border-color: #dae0e5
}

.btn-light.focus, .btn-light:focus {
  color: var(--dark);
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(217, 218, 219, .5)
}

.btn-light.disabled, .btn-light:disabled {
  color: var(--dark);
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: var(--dark);
  background-color: #dae0e5;
  border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(217, 218, 219, .5)
}

.btn-dark {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075)
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark.focus, .btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(32, 32, 32, .075), 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark)
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-outline-primary {
  color: var(--yellow);
  border-color: var(--yellow)
}

.btn-outline-primary:hover {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow)
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: var(--yellow);
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow)
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success {
  color: var(--green);
  border-color: var(--green)
}

.btn-outline-success:hover {
  color: var(--dark);
  background-color: var(--green);
  border-color: var(--green)
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem rgba(112, 195, 129, .5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: var(--green);
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: var(--dark);
  background-color: var(--green);
  border-color: var(--green)
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(112, 195, 129, .5)
}

.btn-outline-info {
  color: var(--blue);
  border-color: var(--blue)
}

.btn-outline-info:hover {
  color: var(--dark);
  background-color: var(--blue);
  border-color: var(--blue)
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem rgba(44, 194, 227, .5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: var(--blue);
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: var(--dark);
  background-color: var(--blue);
  border-color: var(--blue)
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(44, 194, 227, .5)
}

.btn-outline-warning {
  color: var(--yellow);
  border-color: var(--yellow)
}

.btn-outline-warning:hover {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow)
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: var(--yellow);
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: var(--dark);
  background-color: var(--yellow);
  border-color: var(--yellow)
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
  color: #eb4454;
  border-color: #eb4454
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #eb4454;
  border-color: #eb4454
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(235, 68, 84, .5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #eb4454;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #eb4454;
  border-color: #eb4454
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(235, 68, 84, .5)
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:hover {
  color: var(--dark);
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: var(--dark);
  background-color: #f8f9fa;
  border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
  color: var(--dark);
  border-color: var(--dark)
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark)
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: var(--dark);
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark)
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(32, 32, 32, .125), 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
  font-weight: 400;
  color: var(--yellow);
  text-decoration: none
}

.btn-link:hover {
  color: #ba8b00;
  text-decoration: underline
}

.btn-link.focus, .btn-link:focus {
  text-decoration: underline
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-group-lg > .btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem
}

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block + .btn-block {
  margin-top: .5rem
}

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%
}
